import SectionHeader from '@core/components/LandingSectionHeader';

import * as UI from './MainFactorsCards.styles';
import {
  TMainFactor,
  housingConstructionFactors,
  roadBuildingFactors,
} from './data';
import { IMainFactorsCardsProps } from './types';

const headerText = 'Прогнозные данные по итогам 2024 года';

const MainFactorsCards = (props: IMainFactorsCardsProps) => {
  const {
    styles,
  } = props;

  const renderFactorsInfo = (factors: TMainFactor[]) => factors.map((factor) => (
    <UI.FactorInfo key={factor.id}>
      <div>
        {factor.title && (
          <UI.FactorTitleContent>
            {factor.title}
          </UI.FactorTitleContent>
        )}

        <UI.FactorValue>
          {factor.value}
        </UI.FactorValue>

        {factor.text && (
          <UI.FactorText>
            {factor.text}
          </UI.FactorText>
        )}
      </div>

      {factor.summary && (
        <UI.FactorSummary>
          {factor.summary}
        </UI.FactorSummary>
      )}

      {factor.comment && (
        <UI.FactorComment>
          {factor.comment}
        </UI.FactorComment>
      )}
    </UI.FactorInfo>
  ));

  return (
    <UI.Container styles={styles}>
      <SectionHeader>
        {headerText}
      </SectionHeader>

      <UI.FactorsContainer>
        <UI.Factor>
          <UI.FactorTitle>
            Жилищное строительство
          </UI.FactorTitle>

          <UI.FactorsInfoContainer>
            {renderFactorsInfo(housingConstructionFactors)}
          </UI.FactorsInfoContainer>
        </UI.Factor>

        <UI.Factor>
          <UI.FactorTitle>
            Дорожное строительство
          </UI.FactorTitle>

          <UI.FactorsInfoContainer>
            {renderFactorsInfo(roadBuildingFactors)}
          </UI.FactorsInfoContainer>
        </UI.Factor>
        <UI.Factor>
          <UI.FactorTitle>
            формирование комфортной городской среды
          </UI.FactorTitle>

          <div>
            <UI.UrbanFactorText>
              Благоустроено территорий:
            </UI.UrbanFactorText>

            <UI.UrbanFactorValuesContainer>
              <UI.UrbanFactorValue>
                <b>31&nbsp;504</b>
                <UI.UrbanFactorValueText tag="span">
                  общественных
                </UI.UrbanFactorValueText>
              </UI.UrbanFactorValue>

              <UI.UrbanFactorValue>
                <b>40&nbsp;719</b>
                <UI.UrbanFactorValueText tag="span">
                  дворовых
                </UI.UrbanFactorValueText>
              </UI.UrbanFactorValue>
            </UI.UrbanFactorValuesContainer>

            <UI.UrbanFactorSummary>
              Нарастающий итог с 2019 года
            </UI.UrbanFactorSummary>
          </div>
        </UI.Factor>

        <UI.Factor>
          <UI.FactorTitle>
            нормативная работа
          </UI.FactorTitle>

          <UI.WorkFactorInfo>
            <UI.WorkFactorTwo>
              <div>
                <div>
                  <UI.WorkFactorTitleContent>
                    <p>федеральные</p>
                    <p>законы</p>
                  </UI.WorkFactorTitleContent>
                  <UI.FactorValue>
                    <b>39</b>
                  </UI.FactorValue>
                </div>
                <div>
                  <UI.WorkFactorTitleContent>
                    распоряжения президента российской&nbsp;федерации
                  </UI.WorkFactorTitleContent>
                  <UI.FactorValue>
                    <b>0</b>
                  </UI.FactorValue>
                </div>
                <div>
                  <UI.WorkFactorTitleContent>
                    постановления правительства российской&nbsp;федерации
                  </UI.WorkFactorTitleContent>
                  <UI.FactorValue>
                    <b>136</b>
                  </UI.FactorValue>
                </div>
                <div>
                  <UI.WorkFactorTitleContent>
                    распоряжения правительства российской&nbsp;федерации
                  </UI.WorkFactorTitleContent>
                  <UI.FactorValue>
                    <b>287</b>
                  </UI.FactorValue>
                </div>
              </div>
              <UI.WorkFactorBracket>&nbsp;</UI.WorkFactorBracket>
              <div>
                <UI.WorkFactorTitleContent>
                  всего издано документов
                </UI.WorkFactorTitleContent>
                <UI.FactorValue>
                  <b>462</b>
                </UI.FactorValue>
              </div>
            </UI.WorkFactorTwo>

          </UI.WorkFactorInfo>
        </UI.Factor>
      </UI.FactorsContainer>
    </UI.Container>
  );
};

export default MainFactorsCards;
